<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
         

          <div class="auth-wrap ng-tns-c394-2">
            <div class="auth-wrap-left --fd-column ng-tns-c394-2">
              <!-- <h3 class="auth-title ng-tns-c394-2">{{site_settings.name }}:</h3> -->
              <h3 class="auth-title ng-tns-c394-2">
                Delegate Your Tasks to Professionals
              </h3>

              <div class="left-panel ng-tns-c394-2">
                <auth-banner class="ng-tns-c394-2" _nghost-jfn-c393=""><div _ngcontent-jfn-c393="" class="--d-flex --fd-column --h-100per">
                  <div _ngcontent-jfn-c393="" class="auth-banner-list-wrapper --pt-24">
                    <ul _ngcontent-jfn-c393="" class="auth-banner-list">
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="like" class="anticon auth-banner-list-item-icon anticon-like"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="like" aria-hidden="true"><path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 00-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 00471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0142.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">1. Original papers</h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            All papers ordered with us are written from scratch by a team of professionals in the specific chosen subject. We guarantee plagiarism free papers for each school task you order with us
                          </p>
                        </div>
                      </li>
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="credit-card" class="anticon auth-banner-list-item-icon anticon-credit-card"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="credit-card" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-792 72h752v120H136V232zm752 560H136V440h752v352zm-237-64h165c4.4 0 8-3.6 8-8v-72c0-4.4-3.6-8-8-8H651c-4.4 0-8 3.6-8 8v72c0 4.4 3.6 8 8 8z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">2. Best grades </h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            All our writers use physical or online libraries and journals to conduct adequate research before writing any assignment. Please ensure that you upload all relevant instructions and rubrics.
                          </p>
                        </div>
                      </li>
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="safety-certificate" class="anticon auth-banner-list-item-icon anticon-safety-certificate"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="safety-certificate" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5 214 654.3V226.7l298-101.6 298 101.6v427.6zm-405.8-201c-3-4.1-7.8-6.6-13-6.6H336c-6.5 0-10.3 7.4-6.5 12.7l126.4 174a16.1 16.1 0 0026 0l212.6-292.7c3.8-5.3 0-12.7-6.5-12.7h-55.2c-5.1 0-10 2.5-13 6.6L468.9 542.4l-64.7-89.1z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">3. Confidentiality</h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            
                            We understand the nature of our business and have, therefore,  <span _ngcontent-jfn-c393="" class="--fw-600">upgraded our systems to the highest security level {L3S}</span> or Level 3 security for systems. With all systems not being limited or subject to  any US laws or jurisdiction.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                </auth-banner>
              </div>
            </div>

            <div class="right-panel ng-tns-c394-2">
              <!-- <h3 class="auth-title ng-tns-c394-2 font-weight-bold ">{{site_settings.name }}:</h3>  -->
              <div class="text-center mb-3"><h5 class="mb-0 bold-title ">Pristine Writers</h5>
                <div class="sub-title-hint">Let’s recover your password in no time!</div>
              </div>
              <router-outlet class="stretch-outlet ng-tns-c394-2 ng-trigger ng-trigger-EaseInOutAnimation"></router-outlet><auth-login-component class="auth-container ng-star-inserted" _nghost-jfn-c395=""><div _ngcontent-jfn-c395="" class="--d-flex --fd-column --jc-center --flex-1">
                <div _ngcontent-jfn-c395="">
                  <form  @submit.prevent="submitReset" _ngcontent-jfn-c395="" novalidate="" nz-form="" nzlayout="vertical" class="--mb-16 new-auth-form ant-form ant-form-vertical ng-pristine ng-invalid ng-star-inserted ng-touched">
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-3 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-3"><div class="ant-form-item-control-input-content ng-tns-c149-3">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-3">Email</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-3 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="mail" nztheme="outline" class="anticon anticon-mail ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="mail" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0068.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></i>
                          </span>
                            <input required _ngcontent-jfn-c395="" v-model="forgotPassword.email"  nz-input="" autocomplete="on" placeholder="Email" type="email" class="ant-input ant-input-lg ng-pristine ng-invalid ng-star-inserted ng-touched">
                          </nz-input-group>
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>

                   
                    <div _ngcontent-jfn-c395="" class="--d-flex --ai-center --mb-16">
                      <router-link :to="{ name: 'Login' }" _ngcontent-jfn-c395="" class="nz-link" >Remember password?</router-link>
                    </div>

                    <button type="submit"
                    :disabled="forgotPassword.busy"
                     _ngcontent-jfn-c395=""   nz-button="" nzblock="" class="ant-btn ant-btn-auth  text-white  --bdrs-24 ant-btn-lg ant-btn-block"><span class="ng-star-inserted">
                      {{ forgotPassword.busy ? "Loading.." : "email me reset password link"}}
                      </span><i _ngcontent-jfn-c395="" nz-icon="" nztype="arrow-right" class="anticon ant-btn-auth-arrow-rt anticon-arrow-right">
                        <!-- <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-right" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg> -->
                      </i><span class="ng-star-inserted">
                    </span></button>
                  </form>
                </div>
              </div>

              <div _ngcontent-jfn-c395="" class="--text-color-3 --fs-16 --ta-center --pt-24 ng-star-inserted">
                Don't have an account yet?
                <router-link
                    :to="{ name: 'Register' }" _ngcontent-jfn-c395="" class="nz-link" >Create Account</router-link>
              </div>
              </auth-login-component>
            </div>
          </div>
          <!-- Login card -->
          <!-- <form
            class="login-form"
            ref="login-form"
            @submit.prevent="submitReset"
          >
            <div class="card  orderlisting--card mb-0 bg-white rounded-custom p-2 p-sm-3 ">
              <div class="card-body">
                <div class="text-center mb-3">
                  
                  <h5 class="mb-0 bold-title ">No panic!</h5>
                  <div class="sub-title-hint">Let’s recover your password in no time!</div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="text"
                    v-model="forgotPassword.email"
                    name="email"
                    class="form-control height-60"
                    required
                    placeholder="Enter Email"
                  />
                </div>
                <div class=" d-flex my-1 align-items-center justify-content-end w-100 ">
                <router-link :to="{ name: 'Login' }" class="ml-auto"
                  >Remember password?</router-link
                >
                </div>



                <div class="form-group">
                  <button
                    type="submit"
                    :disabled="forgotPassword.busy"
                    class="btn btn--custom  btn-block"
                  >
                    {{ forgotPassword.busy ? "Loading.." : "Get Password Reset Link"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div class="form-group text-center text-muted content-divider">
                  <span class="px-2">Don't have an account?</span>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Register' }"
                    class="btn btn--primary py-2 btn-block"
                    style="border-radius: 20px !important;"
                    >Sign up</router-link
                  >
                </div>

                <span class="form-text text-center text-muted"
                  >By continuing, you're confirming that you've read our
                  <a href="#">Terms &amp; Conditions</a> and
                  <a href="#">Cookie Policy</a></span
                >
              </div>
            </div>
          </form> -->
          <!-- /login card -->



        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      forgotPassword: new this.$Form({
        email: "",
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["doSendPasswordResetLink", "doGetAuthUser"]),
    submitReset() {
      this.doSendPasswordResetLink(this.forgotPassword)
        .then(() => {
          

          this.$swal({
                icon: 'success',
                title: 'Success',
                text: "Check your email for a password reset link.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
        })
        .catch((err) => {
          console.log("err", err);
          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error ",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        });
    },
  },
  mounted() {
    this.$parent.$emit("loader:hide");
  },
};
</script>


